import React, { useMemo } from "react";
import HashLoader from "react-spinners/HashLoader";

import "./Spinner.css"

const Spinner = ({ loading }) => {
  const overrideCSS = useMemo(() => {
    return {
      display: "block",
      margin: "0 auto",
    };
  }, []);

  return (
    <div className={"spinner"}>
      <HashLoader
        cssOverride={overrideCSS}
        loading={loading}
        color={"orange"}
        size={75}
        aria-label="Loading Spinner"
        data-testid="loader"
        speedMultiplier={0.5}
      />
    </div>
  );
};

export default Spinner;
