import React, { useContext } from "react";
import Button from "../Button/Button";
import AuthContext from "../../context/AuthContext";
import { useTelegram } from "../../hooks/useTelegram";

import "./Header.css";

const Header = () => {
  const { user, onClose } = useTelegram();
  const { isAdmin } = useContext(AuthContext)

  return (
    <div className={"header"}>
      <Button onClick={onClose}>Закрыть</Button>
      <span className={"username"}>@{user?.username || "nameless"} {isAdmin && "[admin]"}</span>
    </div>
  );
};

export default Header;
