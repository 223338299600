import React, { useState, useEffect, useCallback, useContext, useMemo } from "react";
import HomeworkItem from "../HomeworkItem/HomeworkItem";
import Notice from "../Notice/Notice";
import NoData from "../NoData/NoData"
import VerticalPanel from "../VerticalPanel/VerticalPanel";
import LoadingContext from "../../context/LoadingContext";
import axios from "../../services/instance";

import styles from "./HomeworkList.module.css";

const HomeworkList = () => {
  const [homeworks, setHomeworks] = useState([]);
  const { toggleLoading } = useContext(LoadingContext);

  useEffect(() => {
    toggleLoading();

    axios.get("/user_tasks/homeworks")
      .then(({data}) => {
        setHomeworks(data);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        toggleLoading();
      });
  }, []);

  const onUpdate = useCallback(
    (body) => {
      // Найти изменяемый объект Homework по userId
      const userHomework = homeworks.find((x) => x.userId === body.userId);

      // Исключить проверенную задачу из списка Tasks объекта Homework
      const filteredTasks = userHomework.tasks.filter(
        (x) => x.taskId !== body.taskId
      );
      const newUserHomework = { ...userHomework, tasks: filteredTasks };

      // Если список остался пустым, то удалить объект Homework из общего списка.
      let newHomeworks;

      const showUser = filteredTasks.length !== 0;

      if (showUser) {
        newHomeworks = homeworks.map((x) =>
          x.userId === body.userId ? newUserHomework : x
        );
      } else {
        newHomeworks = homeworks.filter((x) => x.userId !== body.userId);
      }

      setHomeworks(newHomeworks);
    },
    [homeworks]
  );

  const pageContent = useMemo(() => 
    homeworks.length !== 0 ? (
      <VerticalPanel>
        {homeworks.map((item) => (
          <HomeworkItem key={item.id} homework={item} onUpdate={onUpdate} />
        ))}
      </VerticalPanel>
    ) : (
      <NoData />
    ), [homeworks, onUpdate]);

  return (
    <>
      <Notice content="Запросы на проверку заданий" />
      {pageContent}
    </>
  );
};

export default HomeworkList;
