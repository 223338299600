import React, { useState, useMemo, useCallback } from "react";
import { Snackbar, Alert } from "@mui/material";

const NotificationContext = React.createContext({
  notification: null,
  setNotification: () => {},
});

const AUTO_HIDE_DURATION = 4000;

export const NotificationContextProvider = (props) => {
  const [notification, setNotification] = useState(null);
  const { message, type } = notification || {};

  const cleanNotification = useCallback(() => setNotification({}), []);

  const notificationComponent = useMemo(
    () => (
      <Snackbar
        open={!!message}
        autoHideDuration={AUTO_HIDE_DURATION}
        onClose={cleanNotification}
      >
        <Alert severity={type} sx={{ width: "100%" }}>
          {message}
        </Alert>
      </Snackbar>
    ),
    [message, type, cleanNotification]
  );

  const contextValue = [
    notificationComponent,
    setNotification,
  ];

  return (
    <NotificationContext.Provider value={contextValue}>
      {props.children}
    </NotificationContext.Provider>
  );
};

export default NotificationContext;
