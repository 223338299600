import React, { useState, useCallback, useMemo, useEffect } from "react";
import { useLoading } from "../../hooks/useLoading";
import { useNotification } from "../../hooks/useNotification";
import axios from "../../services/instance";
import {
  Paper,
  Typography,
  TextField,
  FormControl,
  FormControlLabel,
  FormLabel,
  RadioGroup,
  Radio,
  Button,
  Box,
} from "@mui/material";

const DEPARTMENT_OPTIONS = [
  { label: "SCCT", value: 0 },
  { label: "SCM", value: 1 },
  { label: "Softo", value: 2 },
  { label: "TG", value: 3 },
  { label: "HQ", value: 4 },
  { label: "Other", value: 5 },
];

const LEAVE_REASON_OPTIONS = [
  { label: "Плановый отпуск", value: 0 },
  { label: "Личные обстоятельства", value: 1 },
  { label: "Другое", value: 2 },
];

const DAY_PART_OPTIONS = [
  { label: "AM", value: 0 },
  { label: "PM", value: 1 },
  { label: "Full day", value: 2 },
];

const PLANNED_VACATION_REASON = 0;
const OTHER_LEAVE_REASON = 2;

const NUMERIC_FIELDS = ["department", "leaveReason", "dayPart"];

const CONTAINER_STYLE = { p: 1 };
const INPUT_STYLE = { p: 2, mt: 1 };
const TF_STYLE = { mb: 1 };

const VacationForm = () => {
  const { toggleLoading } = useLoading();
  const [errors, setErrors] = useState({});
  const [notification, setNotification] = useNotification();
  const [remainingDays, setRemainingDays] = useState({});
  const [vacation, setVacation] = useState({});
  const {
    department,
    start,
    end,
    leaveReason,
    leaveReasonOther,
    dayPart,
    comment,
  } = useMemo(() => vacation, [vacation]);

  const { prevYearLeft, totalYearLeft } = useMemo(
    () => remainingDays,
    [remainingDays]
  );

  useEffect(() => {
    toggleLoading();

    axios
      .get("/vacations/remaining_days")
      .then(({ data }) => {
        setRemainingDays(data);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        toggleLoading();
      });
  }, []);

  const handleInputChange = useCallback((e) => {
    const { name, value } = e.target;

    let updatedValue = value;

    if (NUMERIC_FIELDS.includes(name)) {
      updatedValue = Number(value);
    }

    setVacation((prevState) => ({
      ...prevState,
      [name]: updatedValue,
    }));
  }, []);

  useEffect(() => {
    validateDate();
  }, [start, end]);

  const validateDate = useCallback(() => {
    const error = start > end && "Дата конца должна быть позже даты начала";

    setErrors((prevErrors) => ({
      ...prevErrors,
      end: error,
    }));
  }, [start, end]);

  const hasErrors = useMemo(
    () => Object.values(errors).some((error) => !!error),
    [errors]
  );

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault();

      if (hasErrors) {
        return; // If there are errors, do not proceed with the submission
      }

      const sendVacationRequest = async () => {
        toggleLoading();

        try {
          await axios.post("/vacations", vacation);

          setNotification({
            message: "Vacation request submitted successfully",
            type: "success",
          });
          // don`t reset the state, otherwise the components become uncontrollable
        } catch (error) {
          setNotification({
            message: "Failed to submit vacation request.",
            type: "error",
          });
          console.error(error);
        }

        toggleLoading();
      };

      sendVacationRequest();
    },
    [vacation, hasErrors]
  );

  /* Radio options */
  const renderRadio = useCallback(
    ({ value, label }) => (
      <FormControlLabel
        key={value}
        value={value}
        control={<Radio required />}
        label={label}
      />
    ),
    []
  );

  const renderRadioOptions = useCallback(
    (options) => options.map(renderRadio),
    [renderRadio]
  );

  const departmentOptions = useMemo(
    () => renderRadioOptions(DEPARTMENT_OPTIONS),
    [renderRadioOptions]
  );

  const leaveReasonOptions = useMemo(
    () => renderRadioOptions(LEAVE_REASON_OPTIONS),
    [renderRadioOptions]
  );

  const dayPartOptions = useMemo(
    () => renderRadioOptions(DAY_PART_OPTIONS),
    [renderRadioOptions]
  );

  /* Optional questions */
  const showDayPart = useMemo(
    () => start && end && start === end,
    [start, end]
  );

  const showComment = useMemo(
    () => leaveReason !== PLANNED_VACATION_REASON,
    [leaveReason]
  );

  const remainingDaysText = useMemo(
    () =>
      remainingDays &&
      (totalYearLeft >= 0 ? (
        <Box>
          📅 В этом году на балансе доступно <b>{totalYearLeft}</b> дней.
        </Box>
      ) : (
        <Box>
          Вы вышли за доступные пределы на <b>{Math.abs(remainingDays)}</b> дней.
        </Box>
      )),
    [remainingDays, totalYearLeft]
  );

  const prevYearLeftText = useMemo(
    () =>
      remainingDays &&
      prevYearLeft > 0 && (
        <>
          <Box>
           ❗Среди них <b>{prevYearLeft}</b> дней - остаток предыдущего года.
          </Box>
          <Box>
            ❗Если остатки не будут потрачены вовремя, то их количество будет
            уменьшено вдвое.
          </Box>
        </>
      ),
    [remainingDays, prevYearLeft]
  );

  return (
    <Paper sx={CONTAINER_STYLE}>
      <Paper sx={INPUT_STYLE}>
        <Typography variant="h6">Vacation / Time Off Request</Typography>
      </Paper>
      <Paper sx={INPUT_STYLE}>
        <Typography>{remainingDaysText}</Typography>
        <Typography>{prevYearLeftText}</Typography>
      </Paper>
      <form onSubmit={handleSubmit}>
        <Paper sx={INPUT_STYLE} elevation={2}>
          <FormControl fullWidth component="fieldset">
            <FormLabel>Подразделение в котором вы работаете</FormLabel>
            <RadioGroup
              name="department"
              value={department}
              onChange={handleInputChange}
            >
              {departmentOptions}
            </RadioGroup>
          </FormControl>
        </Paper>
        <Paper sx={INPUT_STYLE} elevation={2}>
          <FormControl fullWidth component="fieldset" variant="standard">
            <FormLabel>Тип отсутствия</FormLabel>
            <RadioGroup
              name="leaveReason"
              value={leaveReason}
              onChange={handleInputChange}
            >
              {leaveReasonOptions}
            </RadioGroup>
          </FormControl>

          {leaveReason === OTHER_LEAVE_REASON && (
            <TextField
              required
              fullWidth
              variant="standard"
              name="leaveReasonOther"
              value={leaveReasonOther}
              onChange={handleInputChange}
            />
          )}
        </Paper>
        <Paper sx={INPUT_STYLE} elevation={2}>
          <TextField
            required
            fullWidth
            variant="standard"
            label="Дата начала отпуска"
            type="date"
            name="start"
            value={start}
            onChange={handleInputChange}
            error={!!errors.start}
            helperText={errors.start}
            sx={TF_STYLE}
          />
          <TextField
            required
            fullWidth
            variant="standard"
            label="Дата завершения отпуска"
            type="date"
            name="end"
            value={end}
            onChange={handleInputChange}
            error={!!errors.end}
            helperText={errors.end}
            sx={TF_STYLE}
          />
          {showDayPart && (
            <FormControl fullWidth component="fieldset" variant="standard">
              <FormLabel>Время отсутствия (*если отпуск 1 день)</FormLabel>
              <RadioGroup
                row
                name="dayPart"
                value={dayPart}
                onChange={handleInputChange}
              >
                {dayPartOptions}
              </RadioGroup>
            </FormControl>
          )}
        </Paper>

        {showComment && (
          <Paper sx={INPUT_STYLE} elevation={2}>
            <TextField
              fullWidth
              variant="standard"
              label="Причины отсутствия и другие комментарии"
              name="comment"
              value={comment}
              onChange={handleInputChange}
            />
          </Paper>
        )}

        <Button type="submit" variant="contained" color="primary">
          Отправить запрос
        </Button>
      </form>

      {/* Snackbar */}
      {notification}
    </Paper>
  );
};

export default VacationForm;
