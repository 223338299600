import React, { useState, useEffect } from "react";
import axios from "../services/instance";
import { useTelegram } from "../hooks/useTelegram";
import { useLoading } from "../hooks/useLoading";

const AuthContext = React.createContext({
  isSignedIn: false,
  isAdmin: false,
});

export const AuthContextProvider = (props) => {
  const { userId } = useTelegram();
  const { toggleLoading } = useLoading();
  const [isSignedIn, setIsSignedIn] = useState();
  const [isAdmin, setIsAdmin] = useState();

  useEffect(() => {
    checkUserPermissions(userId);
  }, []);

  const checkUserPermissions = (userId) => {
    if (!userId) return;

    toggleLoading();

    axios.get(`users/${userId}`)
      .then(({data}) => {
        const { id: userId, isAdmin } = data;
        
        setIsSignedIn(!!userId);
        setIsAdmin(isAdmin);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        toggleLoading();
      });
  };

  const contextValue = {
    isSignedIn: isSignedIn,
    isAdmin: isAdmin,
  };

  return (
    <AuthContext.Provider value={contextValue}>
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
