import React from "react";
import VerticalPanel from "../VerticalPanel/VerticalPanel";
import Wrapper from "../Wrapper/Wrapper";

const MessageBox = (props) => {
  return (
    <VerticalPanel>
      <Wrapper className={props.className}>
        <h4>{props.children}</h4>
      </Wrapper>
    </VerticalPanel>
  );
};

export default MessageBox;
