import React, { useState, useEffect, useCallback } from "react";
import { useTelegram } from "../../hooks/useTelegram";
import Select from "react-select";
import Notice from "../Notice/Notice";
import { useLoading } from "../../hooks/useLoading";
import { useNotification } from "../../hooks/useNotification";
import axios, { sendFormData } from "../../services/instance";
import {
  Paper,
  Input,
  TextField,
  FormControl,
  FormLabel,
  Typography,
} from "@mui/material";

const CONTAINER_STYLE = { p: 1, height: "100%" };
const INPUT_STYLE = {
  p: 2,
  mt: 1,
  display: "flex",
  flexDirection: "column",
  gap: "1.2rem", // Add spacing between elementss
};

const MessageSender = () => {
  const { tg, onToggleButton } = useTelegram();
  //
  const { toggleLoading } = useLoading();
  const [notification, setNotification] = useNotification();
  //
  const [message, setMessage] = useState("");
  const [options, setOptions] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectedImage, setSelectedImage] = useState("");

  useEffect(() => {
    toggleLoading();

    axios
      .get("/groups")
      .then(({ data }) => {
        const options = data.map((it) => {
          return { value: it.id, label: it.title };
        });
        setOptions(options);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        toggleLoading();
      });
  }, []);

  const handleSubmit = useCallback(() => {
    const sendMessage = async () => {
      toggleLoading();

      try {
        const selectedGroups =
          selectedOptions.length > 0
            ? selectedOptions.map((it) => it.value)
            : "all";

        const formData = new FormData();
        formData.append("message", message);
        formData.append("groups", selectedGroups);
        if (selectedImage) {
          formData.append("image", selectedImage);
        }
        
        await sendFormData("/send_messages", formData);

        setNotification({
          message: "Message sent successfully!",
          type: "success",
        });
      } catch (error) {
        setNotification({
          message: "An error occurred while sending the message!",
          type: "error",
        });
        console.error(error);
      }

      toggleLoading();
    };

    sendMessage();

    // TODO: Разобраться как корректно чистить MUI-элементы.
    // Пока что не очищать формы и уведомлять пользователя о корректной отправке.
    //setMessage('');
    //setSelectedImage(null);
  }, [message, selectedImage, selectedOptions]);

  useEffect(() => {
    tg.MainButton.setText("✉️ Отправить сообщение");

    const showButton = message && message.length > 0;
    onToggleButton(showButton);
  }, [selectedOptions, message]);

  useEffect(() => {
    tg.onEvent("mainButtonClicked", handleSubmit);
    return () => {
      tg.offEvent("mainButtonClicked", handleSubmit);
    };
  }, [handleSubmit]);

  const handleSelect = useCallback((data) => {
    setSelectedOptions(data);
  }, []);

  const handleInput = useCallback((e) => {
    setMessage(e.target.value);
  }, []);

  const handleImageChange = useCallback((e) => {
    setSelectedImage(e.target.files[0]);
  }, []);

  return (
    <>
      <Notice content="Выберите группы получателей и введите сообщение перед отправкой" />
      <Paper sx={CONTAINER_STYLE}>
        <form>
          <Select
            options={options}
            placeholder="Оставьте поле пустым, если необходимо разослать сообщение всем."
            value={selectedOptions}
            onChange={handleSelect}
            isSearchable={true}
            isMulti
          />

          <Paper sx={INPUT_STYLE} elevation={2}>
            <TextField
              required
              fullWidth
              placeholder="Введите рассылаемое сообщение:"
              onChange={handleInput}
              value={message}
              variant="standard"
              label="Сообщение"
              name="message"
              multiline
            />
          </Paper>

          <Paper sx={INPUT_STYLE} elevation={2}>
            <FormControl>
              <FormLabel>Изображение</FormLabel>
              <Input
                name="image"
                type="file"
                accept="image/*" // Optional: Restrict file types to images
                onChange={handleImageChange}
              />
            </FormControl>
            {selectedImage && (
              <FormControl>
                <Typography variant="h6">Предпросмотр изображения:</Typography>
                <img
                  src={URL.createObjectURL(selectedImage)}
                  alt="Selected image"
                  width="200"
                />
              </FormControl>
            )}
          </Paper>
        </form>

        {/* Snackbar */}
        {notification}
      </Paper>
    </>
  );
};

export default MessageSender;
