import React from 'react'

import styles from "./VerticalPanel.module.css"

const VerticalPanel = (props) => {
  const panelClasses = `${styles.panel} ${props.className}`

  return (
    <div className={panelClasses} onClick={props.onClick}>{props.children}</div>
  )
}

export default VerticalPanel
