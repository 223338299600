const tg = window.Telegram.WebApp;

export const useTelegram = () => {
  const onClose = () => {
    tg.close();
  };

  const onToggleButton = (showButton) => {
    if (!showButton) {
      tg.MainButton.hide();
    } else {
      tg.MainButton.show();
    }
  };

  return {
    onClose,
    onToggleButton,
    tg,
    user: tg.initDataUnsafe?.user,
    userId: tg.initDataUnsafe?.user?.id,
    queryId: tg.initDataUnsafe?.query_id
  };
};
