import React from "react";
import MessageBox from "../MessageBox/MessageBox";

import styles from "../MessageBox/MessageBox.module.css";

const NoPermission = () => {
  return (
    <MessageBox className={styles.warning}>
      Вы не были авторизованы, чтобы посетить данную страницу. 
      Попробуйте перезагрузить страницу, или повторите попытку позже.
    </MessageBox>
  );
};

export default NoPermission;
