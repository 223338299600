import React from "react";
import MessageBox from "../MessageBox/MessageBox";

import styles from "../MessageBox/MessageBox.module.css";

const NoMatch = () => {
  return (
    <MessageBox className={styles.warning}>
      По данному адресу нет никакой страницы!
    </MessageBox>
  );
};

export default NoMatch;
