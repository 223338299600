import React from 'react'

import styles from "./Button.module.css"

const Button = (props) => {
  const classNames = `${styles.button} ${props.className}`

  return (
    <button {...props} className={classNames} />
  )
}

export default Button