import React, { useState, useCallback, useMemo } from "react";
import Wrapper from "../Wrapper/Wrapper";
import TaskForm from "../TaskForm/TaskForm";
import Box from "../Box/Box";

import styles from "./TaskItem.module.css";
import boxes from "../Box/Box.module.css";
import CollapsibleText from "../CollapsibleText/CollapsibleText";

const ALL_STATUSES = {
  unfinished: "🔴",
  done: "✅",
  pending: "⌛",
  denied: "🚫",
};
const INACCESIBLE_STATUSES = ["done", "pending"];

const TaskItem = ({ task, onUpdate }) => {
  const [showForm, setShowForm] = useState(false);
  const { title, description, url, points, status, comment } = task;

  const toggleShowForm = useCallback(() => {
    setShowForm((prev) => !prev);
  }, []);

  const linkExists = useMemo(() => !!url, [url]);
  const commentExists = useMemo(() => !!comment, [comment]);
  const showSubmit = useMemo(
    () => !INACCESIBLE_STATUSES.includes(status),
    [status]
  );

  return (
      <Wrapper>
        <div className={styles.header}>
          <div className={styles.title}>
            {ALL_STATUSES[status]} {title}
          </div>
          <div className={styles.interactions}>
            {showSubmit && <Box onClick={toggleShowForm}>📤</Box>}
            {linkExists && (
              <Box>
                <a href={`${url}`} target="_blank">
                  🔍
                </a>
              </Box>
            )}
            <Box className={boxes["box-dark"]}>{points}</Box>
          </div>
        </div>
        {showForm ? (
          <TaskForm task={task} onUpdate={onUpdate} onClose={toggleShowForm} />
        ) : (
          <CollapsibleText caption={"Описание задачи:"} content={description} />
        )}
        {commentExists && ( 
          <CollapsibleText caption={"Комментарий HR:"} content={comment} />
        )}
      </Wrapper>
  );
};

export default TaskItem;
