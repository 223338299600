import React, { useCallback } from "react";
import Wrapper from "../Wrapper/Wrapper" 

import styles from './GroupItem.module.css';

const SUBSCRIPTION_BELL = "🔔";

const GroupItem = ({ group, onCheck }) => {
  const { title, selected } = group;

  const handleCheck = useCallback(() => {
    onCheck(group);
  }, [onCheck, group]);

  return (
    <Wrapper>
      <div className={styles.header} onClick={handleCheck}>
        <div className={styles.title}>
          {title}
        </div>
        {selected && <div>{SUBSCRIPTION_BELL}</div>}
      </div>
    </Wrapper>
  );
};

export default GroupItem;
