import React, { useState, useCallback, useMemo, useContext } from "react";
import Box from "../Box/Box";
import TextInput from "../TextInput/TextInput";
import LoadingContext from "../../context/LoadingContext";

import axios from "../../services/instance";

import styles from "./HomeworkTask.module.css";

const HOMEWORK_STATUSES = { true: "done", false: "denied" };

const HomeworkTask = ({ userId, task, onUpdate }) => {
  const { toggleLoading } = useContext(LoadingContext);
  const [comment, setComment] = useState();
  const [showComment, setShowComment] = useState(false);

  const { taskId, title, url } = task;

  const handleInput = useCallback((e) => {
    setComment(e.target.value);
  }, []);

  const toggleShowComment = useCallback(() => {
    setShowComment((prev) => !prev);
  }, []);

  const handleSubmit = useCallback(
    (status) => {
      const updateUserTask = async () => {
        toggleLoading();

        try {
          const body = {
            userId,
            taskId,
            status: HOMEWORK_STATUSES[status],
            comment: comment,
          };

          await axios.post("/user_tasks/homework_reply", body);
        } catch (error) {
          console.error(error);
        }

        toggleLoading();
      };

      updateUserTask();
      onUpdate({ userId, taskId });
    },
    [userId, taskId, comment, onUpdate]
  );

  const linkExists = useMemo(() => !!url, [url]);

  return (
    <div className={styles.task}>
      <div className={styles.title}>{title}</div>
      <div className={styles.interactions}>
        {linkExists && (
          <Box>
            <a href={`${url}`} target="_blank">
              🔍
            </a>
          </Box>
        )}
        <Box onClick={toggleShowComment}>📩</Box>
        <Box onClick={handleSubmit.bind(null, true)}>✅</Box>
        <Box onClick={handleSubmit.bind(null, false)}>🚫</Box>
      </div>
      {showComment && (
        <div>
          <TextInput
            value={comment}
            onChange={handleInput}
            placeholder="Введите любой комментарий..."
          />
        </div>
      )}
    </div>
  );
};

export default HomeworkTask;
