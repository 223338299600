import React, { useState } from "react";

const LoadingContext = React.createContext({
  loading: false,
  toggleLoading: () => {},
});

export const LoadingContextProvider = (props) => {
  const [loading, setLoading] = useState(false);

  const toggleLoading = () => {
    setLoading((status) => !status)
  };

  const contextValue = {
    loading: loading,
    toggleLoading: toggleLoading,
  };

  return (
    <LoadingContext.Provider value={contextValue}>
      {props.children}
    </LoadingContext.Provider>
  );
};

export default LoadingContext;
