import React, { useContext } from "react";
import ReactDOM from "react-dom";
import LoadingContext from "../../context/LoadingContext";
import Spinner from "../Spinner/Spinner";

import "./LoadingModal.css";

const Backdrop = () => {
  return <div className="backdrop" />;
};

const Modal = () => {
  return (
    <div className="modal">
      <Spinner />
    </div>
  );
};

const LoadingModal = () => {
  const { loading } = useContext(LoadingContext);

  const portalContent = (
    <React.Fragment>
      {ReactDOM.createPortal(
        <Backdrop />,
        document.getElementById("backdrop-root")
      )}
      {ReactDOM.createPortal(
        <Modal />,
        document.getElementById("overlay-root")
      )}
    </React.Fragment>
  );

  return loading && portalContent;
};

export default LoadingModal;
