import React from "react";

import styles from "./TextInput.module.css";

const TextInput = (props) => {
  const inputStyles = `${styles["text-input"]} ${props.className}`;

  return <input type="text" className={inputStyles} {...props} />;
};

export default TextInput;
