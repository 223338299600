import React, { useState, useEffect, useContext } from "react";
import Notice from "../Notice/Notice";
import Avatar from "../Avatar/Avatar";
import LoadingContext from "../../context/LoadingContext";
import axios from "../../services/instance";

import "./Leaderboard.css";

const LEADER_PLACES = ["🥇", "🥈", "🥉"]; // https://emojiterra.com/

const Leaderboard = () => {
  const { toggleLoading } = useContext(LoadingContext);
  const [leaders, setLeaders] = useState([]);
  const [personal, setPersonal] = useState({});

  useEffect(() => {
    toggleLoading();

    axios.get("/users/stats")
      .then(({data}) => {
        setLeaders(data.leaders);
        setPersonal(data.personal);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        toggleLoading();
      });
  }, []);

  const getPlace = (place) => {
    if (place > LEADER_PLACES.length) return place;
    return LEADER_PLACES[place - 1];
  };

  return (
    <>
      <Notice
        content={`Вы занимаете ${personal.place} место. Накоплено: ${personal.score} очков!\nПродолжайте в том же духе!`}
      ></Notice>
      <h1 className={"caption"}>Топ 10 по рейтингу:</h1>
      <div className={"leaderboard"}>
        <div className={"cell header-cell"}>
          <div>#</div>
          <div></div>
          <div>ФИО</div>
          <div>Очки</div>
        </div>
        {leaders.map((it) => (
          <div key={it.id} className={"cell"}>
            <div className={"place"}>{getPlace(it.place)}</div>
            <Avatar src={it.avatar} alt={it.title} />
            <div>{it.title}</div>
            <div>{it.score}</div>
          </div>
        ))}
      </div>
    </>
  );
};

export default Leaderboard;
