import React from 'react'

import styles from "./Wrapper.module.css"

const Wrapper = (props) => {
  const wrapperClasses = `${styles.wrapper} ${props.className}`

  return (
    <div className={wrapperClasses} onClick={props.onClick}>{props.children}</div>
  )
}

export default Wrapper
