import React from 'react'

import "./Notice.css"

const Notice = ({ content }) => {
  return (
    <div className={'notice'}>{content}</div>
  )
}

export default Notice