import React from "react";

import dummyImage from "../../assets/dummy-cover-photo.png";
import styles from "./Avatar.module.css"

const MAX_HEIGHT = 40;

const Avatar = ({ src, alt }) => {
  return (
    <div className={styles.avatar}>
      <img src={src || dummyImage} alt={alt} height={MAX_HEIGHT} />
    </div>
  );
};

export default Avatar;
