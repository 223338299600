import React, { useState, useEffect, useCallback, useContext, useMemo } from "react";
import TaskItem from "../TaskItem/TaskItem";
import Notice from "../Notice/Notice";
import NoData from "../NoData/NoData"
import VerticalPanel from "../VerticalPanel/VerticalPanel";
import axios from "../../services/instance";
import LoadingContext from "../../context/LoadingContext";

import styles from './TaskList.module.css';

const DONE_STATUS = "done";

const TaskList = () => {
  const [tasks, setTasks] = useState([]);
  const { toggleLoading } = useContext(LoadingContext);

  useEffect(() => {
    toggleLoading();

    axios.get("/tasks")
      .then(({data}) => {
        setTasks(data);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        toggleLoading();
      });
  }, []);

  const onUpdate = useCallback((task) => {
    const newList = tasks.map((x) => x.id === task.id ? task : x)
    setTasks(newList)
  }, [tasks]);

  const allCompleted = useMemo(() => { 
    tasks.every((item) => item.status === DONE_STATUS)
  }, [tasks]);

  const pageContent = useMemo(() =>
    tasks.length !== 0 ? (
      <VerticalPanel>
        {tasks.map((item) => (
          <TaskItem key={item.id} task={item} onUpdate={onUpdate} />
        ))}
        {allCompleted && (
          <div className={styles.info}>
            Поздравляю, вы выполнили все задания за месяц! 🎉
          </div>
        )}
      </VerticalPanel>
    ) : (
      <NoData />
    ), [tasks, onUpdate]);

  return (
    <>
      <Notice content="Список задач на месяц" />
      {pageContent}
    </>
  );
};

export default TaskList;
