import React, { useState, useEffect, useCallback, useContext, useMemo } from "react";
import GroupItem from "../GroupItem/GroupItem";
import Notice from "../Notice/Notice";
import NoData from "../NoData/NoData"
import VerticalPanel from "../VerticalPanel/VerticalPanel";
import { useTelegram } from "../../hooks/useTelegram";
import axios from "../../services/instance";
import LoadingContext from "../../context/LoadingContext";

import styles from './GroupList.module.css';

const GroupList = () => {
  const [groups, setGroups] = useState([]);
  const { tg, onToggleButton } = useTelegram();
  const { toggleLoading } = useContext(LoadingContext);

  useEffect(() => {
    tg.MainButton.setParams({ text: "📤 Обновить группы рассылок" });

    const showButton = groups.length > 0;
    onToggleButton(showButton);
  }, [groups]);

  useEffect(() => {
    toggleLoading();

    axios.get("/groups/subscriptions")
      .then(({data}) => {
        setGroups(data);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        toggleLoading();
      });
  }, []);

  const handleSubmit = useCallback(async () => {
    toggleLoading();

    try {
      const body = groups.map((it) => {
        return { id: it.id, selected: it.selected };
      });

      await axios.post("/user_groups", { data: body });
    } catch (error) {
      console.error(error);
    }

    toggleLoading();
  }, [groups]);

  useEffect(() => {
    tg.onEvent("mainButtonClicked", handleSubmit);
    return () => {
      tg.offEvent("mainButtonClicked", handleSubmit);
    };
  }, [handleSubmit]);

  // change "selected" status of option in list
  const onCheck = useCallback((item) => {
    const newList = groups.map((x) => x.id === item.id ? { ...x, selected: !item.selected } : x)
    setGroups(newList);
  }, [groups]);

  const pageContent = useMemo(() => 
    groups.length !== 0 ? (
      <VerticalPanel>
        {groups.map((item) => (
          <GroupItem key={item.id} group={item} onCheck={onCheck} />
        ))}
      </VerticalPanel>
    ) : (
      <NoData />
    ), [groups, onCheck]);

  return (
    <>
      <Notice content="Выберите группы от которых вы хотите получать оповещения:" />
      {pageContent}
    </>
  );
};

export default GroupList;
