import React from "react";
import MessageBox from "../MessageBox/MessageBox";

const NoMatch = () => {
  return (
    <MessageBox>
      Нет данных для отображения...
    </MessageBox>
  );
};

export default NoMatch;
