import axios from "axios";

export const axiosDefault = axios;

export const axiosConfig = () => ({ 
  baseURL: process.env.REACT_APP_BACKEND_URL,
  headers: { 
    'Content-Type': 'application/json', 
    "Bypass-Tunnel-Reminder": true,
    "User-Id": window.Telegram.WebApp.initDataUnsafe?.user?.id
  },
  transformResponse: [(data) => camelCaseKeys(JSON.parse(data))],
  transformRequest: [(data) => JSON.stringify(snakeCaseKeys(data))],
})

const instance = axios.create(axiosConfig()) 

const camelCaseKeys = (obj) => {
  if (typeof obj !== 'object' || obj === null) {
    return obj;
  }

  if (Array.isArray(obj)) {
    return obj.map(camelCaseKeys);
  }

  const result = {};
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      const camelCaseKey = key.replace(/_([a-z])/g, (match, char) => char.toUpperCase());
      result[camelCaseKey] = camelCaseKeys(obj[key]);
    }
  }

  return result;
}

const snakeCaseKeys = (obj) => {
  if (typeof obj !== 'object' || obj === null) {
    return obj;
  }

  if (Array.isArray(obj)) {
    return obj.map(snakeCaseKeys);
  }

  const result = {};
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      const snakeCaseKey = key.replace(/[A-Z]/g, (match) => `_${match.toLowerCase()}`);
      result[snakeCaseKey] = snakeCaseKeys(obj[key]);
    }
  }

  return result;
}

export const sendFormData = async (path, formdata) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}${path}`, formdata, {
      headers: {
        'Bypass-Tunnel-Reminder': true,
        'User-Id': window.Telegram.WebApp.initDataUnsafe?.user?.id,
      },
    });

    return response;
  } catch (error) {
    console.error('Error:', error);
    throw error; // Re-throw the error if you want to handle it further in the calling code.
  }
};


export default instance;