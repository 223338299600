import React from "react";

import styles from "./Box.module.css"

const Box = (props) => {
  const boxClasses = `${styles.box} ${props.className}`

  return (
    <div className={boxClasses} onClick={props.onClick}>
      {props.children}
    </div>
  );
};

export default Box;
