import React, { useCallback, useState, useMemo } from "react";
import Avatar from "../Avatar/Avatar";
import HomeworkTask from "../HomeworkTask/HomeworkTask";
import Wrapper from "../Wrapper/Wrapper";
import Box from "../Box/Box";

import styles from "./HomeworkItem.module.css";
import boxes from "../Box/Box.module.css";

const HomeworkItem = ({ homework, onUpdate }) => {
  const [collapsed, setCollapsed] = useState(true);
  const { userId, avatar, username, tasks } = homework;

  const toggleCollapsed = useCallback(() => {
    setCollapsed((prev) => !prev);
  }, []);

  const сollapserText = useMemo(() => {
    return collapsed ? "⬆" : "⬇";
  }, [collapsed]);

  return (
    <Wrapper>
      <div className={styles.user}>
        <Avatar src={avatar} alt={username} />
        <div>{username}</div>
        <Box className={boxes["box-transparent"]} onClick={toggleCollapsed}>
          {сollapserText}
        </Box>
      </div>
      {!collapsed && (
        <div className={styles.tasks}>
          {tasks.map((item) => (
            <HomeworkTask key={item.id} userId={userId} task={item} onUpdate={onUpdate} />
          ))}
        </div>
      )}
    </Wrapper>
  );
};

export default HomeworkItem;
