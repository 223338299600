import React, { useState, useCallback, useMemo } from "react";

import styles from "./CollapsibleText.module.css";

const COLLAPSED_LENGTH = 100;

const CollapsibleText = ({ caption, content }) => {
  const [collapsed, setCollapsed] = useState(true);

  const toggleCollapsed = useCallback(() => {
    setCollapsed((prev) => !prev);
  }, []);

  const showCollapser = useMemo(
    () => content.length > COLLAPSED_LENGTH,
    [content]
  );
  
  const contentText = useMemo(() => {
    return collapsed && showCollapser
      ? `${content.slice(0, COLLAPSED_LENGTH)}...`
      : content;
  }, [collapsed, content, showCollapser]);

  const сollapserText = useMemo(() => {
    return collapsed ? "⬇ показать" : "⬆ скрыть";
  }, [collapsed]);

  return (
    <div className={styles.block}>
      <div className={styles.caption}>{caption}</div>
      <pre>{contentText}</pre>
      {showCollapser && (
        <div className={styles.collapser} onClick={toggleCollapsed}>
          {сollapserText}
        </div>
      )}
    </div>
  );
};

export default CollapsibleText;
