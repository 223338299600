import React, { useState, useCallback, useContext } from "react";
import Button from "../Button/Button";
import TextInput from "../TextInput/TextInput";
import LoadingContext from "../../context/LoadingContext";
import axios from "../../services/instance";

import styles from "./TaskForm.module.css";
import btn from "../Button/Button.module.css";

const PENDING_STATUS = "pending";

const TaskForm = ({ task, onClose, onUpdate }) => {
  const { toggleLoading } = useContext(LoadingContext);
  const [url, setUrl] = useState("");
  const [error, setError] = useState();

  const { id, needFile } = task;

  const handleInput = useCallback((e) => {
    setUrl(e.target.value);
  }, []);

  const handleSubmit = useCallback(() => {
    if (needFile && url.length === 0) {
      setError("Ссылка на файл НЕ должна быть пустой!");
      return;
    }

    const saveUserTask = async () => {
      toggleLoading();

      try {
        const body = { id, url, status: PENDING_STATUS };

        await axios.post("/user_tasks", body);
      } catch (error) {
        console.error(error);
      }

      toggleLoading();
    };

    saveUserTask();
    onUpdate({ ...task, status: PENDING_STATUS });
    onClose();
  }, [id, url, needFile, onUpdate, onClose]);

  return (
    <div className={styles.form}>
      <div>Вы уверены, что хотите отправить работу на проверку?</div>
      <div>
        {needFile && (
          <TextInput
            value={url}
            onChange={handleInput}
            placeholder="Вставьте ссылку на Google Docs"
          />
        )}
        {error &&
          <div className={styles.error}>*{error}</div>}
      </div>
      <div className={styles["form-actions"]}>
        <Button className={btn.submit} onClick={handleSubmit}>
          Да, отправить
        </Button>
        <Button className={btn.abort} onClick={onClose}>
          Нет, я передумал
        </Button>
      </div>
    </div>
  );
};

export default TaskForm;
